import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '../../common/Card';

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        let url = `https://api.werclive.xyz/wp-json/wp/v2/posts`;
        axios.get(url).then((res) => {
            const { data, headers } = res;
            setPosts(data);
        });
    }, []);
    console.log('posts', posts);

    return (
        <>
            <section className="bg-gray-100 p-5 min-h-screen">
                <div className="max-w-5xl mx-auto text-center">
                    <h1 className='text-2xl font-bold mb-4'>Posts</h1>
                    <div className='w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-10'>
                        {
                            Object.keys(posts).length ? posts.map(post => {
                                console.log('post', post);
                                return (
                                    <Card post={post} key={post.id} />
                                )
                            }) : <div className='col-span-3 w-5 h-5 absolute left-1/2 top-1/2 rounded-full border-2 border-b-0 border-blue-500 animate-spin' />
                        }
                    </div>



                </div>
            </section>
        </>
    )
}

export default Posts;